



$(document).on("turbolinks:load", function () {
  $(".filter-toggle").on("click", function () {
    $("#hide").toggleClass("hidden");
    var $this = $(this);
    $(this).find(".filter-collapse").toggle();
    return false;
  });

  $("#reset-filter").on("click", function () {
    $("#hide").addClass("hidden");
    $(".filter-collapse-icon").hide();
    $(".filter-expand-icon").show();
    window.history.pushState({}, document.title, window.location.pathname);

  });

  $(".selectize_filter").each(function () {
    var $select = $(this).selectize({
      plugins: ["remove_button"],
      dropdownParent: "body",
      closeAfterSelect: true,
      onChange: function (value, $item) {
        console.log("filter change");
        form = document.querySelector("form");
        Rails.fire(form, "submit");
      },
    });

    
    // Clear selected items from selectize control on  click
    $("#reset-filter").on("click", function () {
      var selectize = $select[0].selectize;
      selectize.clear(true);
    });
  });

  $(".sort_selectize").each(function () {
    var $select = $(this).selectize({
      plugins: ["remove_button"],
      dropdownParent: "body",
      closeAfterSelect: true,
      onChange: function (value, $item) {
        console.log("filter change");
        form = document.querySelector("form");
        Rails.fire(form, "submit");
      },
    });

    // Clear selected items from selectize control on  click
    $("#reset-filter").on("click", function () {
      var selectize = $select[0].selectize;
      selectize.clear(true);
    });
  });
});
