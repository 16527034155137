$(document).on("turbolinks:load", function () {


  $(".copy-order-to-harvest").click(function () {
    var order = $(this).closest('.packs-nested-fields').find('.order_amount')
    var harvest = $(this).closest('.packs-nested-fields').find('.harvest_amount')
    harvest.val(order.val());
  
  });



  $(".harvest-day").datepicker({
    format: "yyyy-mm-dd",
    autoclose: true,
    orientation: "auto bottom",
    todayHighlight: true,
  });

  $(".order-day").datepicker({
    format: "yyyy-mm-dd",
    autoclose: true,
    orientation: "auto bottom",
    todayHighlight: true,
  });



  $(".packsortable").railsSortable({ handle: ".sortHandle" });

  $("form").on("cocoon:after-insert", function (event, addedItem) {
  


    $(".return-column").addClass("hidden");

    $(addedItem).find(".copy-order-to-harvest").click(function () {
      var order = $(this).closest('.packs-nested-fields').find('.order_amount')
      var harvest = $(this).closest('.packs-nested-fields').find('.harvest_amount')
      harvest.val(order.val());
      });
    $(addedItem).find(".packsortable").railsSortable({ handle: ".sortHandle" });
  });
});
