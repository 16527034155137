// unsaved changes
$(document).on("turbolinks:before-visit", function (event) {
  if (
    unsaved &&
    !confirm("You have unsaved changes. Are you sure you want to continue?")
  ) {
    return event.preventDefault();
  } else {
    console.log("saved");
    unsaved = false;
  }
});

$(document).on("turbolinks:load", function () {



       $(".rich_text_area").on('change keyup paste', function(event) {
    console.log("unsaved")
              return (unsaved = true);
    })
    $(".trix-button--icon-attach").click(function(event) {
      console.log("unsaved")
                return (unsaved = true);
      })
  

  
});
