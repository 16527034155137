

//make sure selectize doesn't duplicate
document.addEventListener("turbolinks:before-cache", function () {
  $("[data-toggle='popover']").popover("hide");
  $('[data-toggle="tooltip"]').tooltip("hide");
  $(".selectized").each(function () {
    if (this.selectize != undefined) {
      this.selectize.destroy();
    }
  });
});



document.addEventListener("turbolinks:load", () => {

  // gtag('config', 'G-11CVHLHCTL', {'page_location': event.data.url});

 
  //copy to clipboard
  function copyToClipboard(element) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
  }

  //bulk edit
  $("#checkAll").click(function () {
    $("input:checkbox").not(this).prop("checked", this.checked);
    var length = $(".checkSingle input:checkbox:checked").length;
    $("#selected").html(length + " selected");
    if (length <= 0) {
      $(".edit-selected").hide();
    } else {
      $(".edit-selected").show();
    }
  });

  var length = $(".checkSingle input:checkbox:checked").length;
  if (length <= 0) {
    $(".edit-selected").hide();
  } else if (length > 0) {
    $(".edit-selected").show();
  }

  $(".checkSingle").click(function () {
    var length = $(".checkSingle input:checkbox:checked").length;
    $("#selected").html(length + " selected");
    if (length <= 0) {
      $(".edit-selected").hide();
    } else {
      $(".edit-selected").show();
    }
    if (length > 0) {
      $("#checkAll").prop("checked", true);
    } else {
      $("#checkAll").prop("checked", false);
    }
  });

  $(".datepicker-range").attr("autocomplete", "off");




  $("a.print").click(function () {
    var selected = $(".selectize-input option:selected").text();
    $(".selectize-input").after('<p class="replacement">' + selected + "</p>");
    $(".selectize-input").hide();
    window.print();
  });



  $("[data-toggle='popover']").popover("hide");
  $('[data-toggle="tooltip"]').tooltip("hide");

  $('body').on('click', function (e) {
    $('[data-toggle="popover"]').each(function () {
        //the 'is' for buttons that trigger popups
        //the 'has' for icons within a button that triggers a popup
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            $(this).popover('hide');
        }
    });
});

 

  $(".completed-popover").popover({
    trigger: "hover focus",
  }).on('click', function() {
    $(this).popover('hide')
});


  $(".upgrade-popover").popover({
    trigger: "hover focus",
    html: true,
    placement: "bottom",
    content: "Upgrade your subscription to access"  
  }).on('click', function() {
    $(this).popover('hide')
});



  
  $(".week").datepicker({
    format: "yyyy-mm-dd",
    autoclose: true,
    orientation: "auto bottom",
    todayHighlight: true,
  });

  $(".week").on("change", function (ev) {
    $(this).datepicker("hide");
  });

  $(".week").on("hide", function (e) {
    var value = $(this).val();
    var curr = new Date(value);
    var firstDate = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
    $(this).val(firstDate);
    $(this).datepicker("setDate", firstDate);
  });

  $(".datepicker-range-projection").datepicker({
    format: "yyyy-mm-dd",
    autoclose: true,
    orientation: "auto bottom",
    todayHighlight: true,
  });

  $(".datepicker-range-task").datepicker({
    format: "yyyy-mm-dd",
    autoclose: true,
    orientation: "auto bottom",
    todayHighlight: true,
  });

  $(".datepicker-range-formbuilder").datepicker({
    format: "yyyy-mm-dd",
    autoclose: true,
    orientation: "auto bottom",
    todayHighlight: true,
  });

  $(".input-daterange input").on("change", function (ev) {
    $(this).datepicker("hide");
  });


  $(".input-daterange-day input").on("change", function (ev) {
    $(this).datepicker("hide");
  });

  $(".input-daterange-day input").on("hide", function (e) {
    var startvalue = $("#start-date").val();
    var curr = new Date(startvalue);
    var startfirstDate = new Date(
      curr.setDate(curr.getDate() + 1)
    );
    $("#start-date").val(startfirstDate);
    $("#start-date").datepicker("setDate", startfirstDate);

    var endvalue = $("#end-date").val();
    var curr = new Date(endvalue);
    var endfirstDate = new Date(
      curr.setDate(curr.getDate() + 1)
    );
    $("#end-date").val(endfirstDate);
    $("#end-date").datepicker("setDate", endfirstDate);

    
    
  });
  

  $('[data-toggle="tooltip"]').tooltip({
    trigger: "hover focus",
  });

  $.ajaxSetup({
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
  });
});


