

document.addEventListener("turbolinks:load", () => {



  
  var dataId = $("body").data("params-id");

  var varietyurl = "/seasons/" + dataId + "/varieties";
  var varietyquery = "/seasons/" + dataId + "/varieties.json";

  var customerurl = "/seasons/" + dataId + "/customers";
  var customerquery = "/seasons/" + dataId + "/customers.json";

  var groupurl = "/seasons/" + dataId + "/customer_groups";
  var groupquery = "/seasons/" + dataId + "/customer_groups.json";

  var taskurl = "/seasons/" + dataId + "/crop_tasks";
  var taskquery = "/seasons/" + dataId + "/crop_tasks.json";

  var supplyurl = "/seasons/" + dataId + "/supplies";
  var supplyquery = "/seasons/" + dataId + "/supplies.json";



  var selectizecroptask = ""

  $(".tasktodoselectize").selectize({});
  $(".time_zone_selectize").selectize({});
  $(".selectize_role").selectize({});
  $('.selectize-small').selectize();
  $(".selectize_crop").selectize();
  $(".selectize_email").selectize({});

  
  $(".harvest_unit").selectize();

  var selectizeCallback = null;

  $(".create-unit-modal").on("hide.bs.modal", function(e) {
    if (selectizeCallback != null) {
      selectizeCallback();
      selecitzeCallback = null;
    }
  });

  $("#new_harvestunit").on("submit", function(e) {
    e.preventDefault();
    $.ajax({
      method: "POST",
      url: $(this).attr("action"),
      data: $(this).serialize(),
      success: function(response) {


        if (response.price_per_unit == null) {
          selectizeCallback({value: response.id, text: response.custom_item + " - " + response.harvest_unit});
        } else {
          selectizeCallback({value: response.id, text: response.custom_item + " - " + response.harvest_unit + " $" + parseFloat(response.price_per_unit).toFixed(2)});

       }
        selectizeCallback = null;

        $(".create-unit-modal").modal('toggle');

      }
    });
  });

  $(".harvest_unit_selectize").selectize({
    
    create: function(input, callback) {
      selectizeCallback = callback;

      $(".create-unit-modal").modal();
      $("#new_harvestunit").trigger("reset");

      $("#harvestunit_custom_item").val(input);

      $(".alert").hide();

    }
  });


  $(".selectizelocation").selectize({ plugins: ["remove_button"] });


  $(".selectize_assign").selectize();
  


  $(".selectize-groups").selectize({
    plugins: ["remove_button"],
    valueField: "id",
    labelField: "name",
    searchField: "name",
    preload: true,
    load: function (query, callback) {
      console.log(query);
      $.ajax({
        url: groupquery,
        data: { q: query },
        dataType: "json",
        type: "GET",
        error: function (res) {
          callback();
        },
        success: function (res) {
          callback(res);
        },
      });
    },

    create: function (input, callback) {
      $.ajax({
        url: groupurl,
        data: { customer_group: { name: input.replace(";;", ";") } },
        method: "POST",
        error: function (res) {
          
          callback();
          $(this).val(input.replace(";;", ";"));
                },
        success: function (response) {
          console.log(response);

          $.getJSON(groupquery, function (data) {
            let data_filter = data.filter((element) => element.name == [input.replace(";;", ";")]);
            callback({ id: data_filter[0]["id"], name: input.replace(";;", ";") });
          $(this).val(data_filter[0]["id"]);


          });

        
        },
      });
    },
  });

  $(".selectize_customer").selectize({
    maxItems: 1,
    delimiter: ';;',
    valueField: "name",
    labelField: "name",
    searchField: "name",
    preload: true,
    load: function (query, callback) {
      console.log(query);
      $.ajax({
        url: customerquery,
        data: { q: query },
        dataType: "json",
        type: "GET",
        error: function (res) {
          callback();
        },
        success: function (res) {
          callback(res.slice(0, 10));
        },
      });
    },
    render: {
      item: function (data, escape) {
        return (
          "<div class = 'select-id' data-id='" +
          data.id +
          "'>" +
          data.name +
          "</div>"
        );
      },
      options: function (data, escape) {
        return "<div>" + data.name + "</div>";
      },
    },
    onChange: function (value) {
      let id_name = $(this)[0].$input["0"].name.replace(
        "[customer_name]",
        "[customer_id]"
      );
      $.getJSON(customerquery, function (data) {
        let data_filter = data.filter((element) => element.name == [value]);
        $("input[name='" + id_name + "']").val(data_filter[0]["id"]);
        console.log(data_filter[0]["id"]);
      });
    },
    create: function (input, callback) {
      $.ajax({
        url: customerurl,
        data: { customer: { name: input.replace(";;", ";") } },
        method: "POST",
        error: function (res) {
          
          callback();
          $(this).val(input.replace(";;", ";"));

        },
        success: function (response) {

          
          console.log(response);
          callback({ value: response.id, name: input.replace(";;", ";") });
          $(this).val(input.replace(";;", ";"));
        },
      });
    },
  });

  $(".selectizevariety").selectize({
    maxItems: 1,
    delimiter: ';;',
    valueField: "variety",
    labelField: "variety",
    searchField: "variety",
    preload: true,
    load: function (query, callback) {
      console.log(query);
      $.ajax({
        url: varietyquery,
        data: { q: query },
        dataType: "json",
        type: "GET",
        error: function (res) {
          callback();
        },
        success: function (res) {
          callback(res.slice(0, 10));
        },
      });
    },
    render: {
      item: function (data, escape) {
        return (
          "<div class = 'select-id' data-id='" +
          data.id +
          "'>" +
          data.variety +
          "</div>"
        );
      },
      options: function (data, escape) {
        return "<div>" + data.variety + "</div>";
      },
    },
    onChange: function (value) {
      let id_name = $(this)[0].$input["0"].name.replace(
        "[variety]",
        "[varietyselector_id]"
      );

      $.getJSON(varietyquery, function (data) {
        let data_filter = data.filter((element) => element.variety == [value]);
        $("input[name='" + id_name + "']").val(data_filter[0]["id"]);
        console.log(data_filter[0]["id"]);
      });
    },

    create: function (input, callback) {
      $.ajax({
        url: varietyurl,
        data: { varietyselector: { variety: input.replace(";;", ";") } },
        method: "POST",
        error: function (res) {
          
          callback();
          $(this).val(input.replace(";;", ";"));
                },
        success: function (response) {
          console.log(response);
          callback({ value: response.id, variety: input.replace(";;", ";") });
          $(this).val(input.replace(";;", ";"));
        },
      });
    },
  });

  

$(".selectizecroptask").selectize({
    maxItems: 1,
    delimiter: ';;',
    valueField: "task",
    labelField: "task",
    searchField: "task",
    preload: true,
    load: function (query, callback) {
      console.log(query);
      $.ajax({
        url: taskquery,
        data: { q: query },
        dataType: "json",
        type: "GET",
        error: function (res) {
          callback();
        },
        success: function (res) {
          callback(res);
        },
      });
    },
    render: {
      item: function (data, escape) {
  
        return (
          "<div class = 'select-id' data-id='" +
          data.id +
          "'>" +
          data.task +
          "</div>"
        );
      },
      options: function (data, escape) {
        return "<div>" + data.task + "</div>";
      },
    },
    onChange: function (value) {
      let id_name = $(this)[0].$input["0"].name.replace(
        "[task]",
        "[tasklist_id]"
      );
      let select_input = $(this)[0].$input["0"]
  
      $.getJSON(taskquery, function (data) {
        let data_filter = data.filter((element) => element.task == [value]);

        if (data_filter[0] == null) {
          $("input[name='" + id_name + "']").val(null);
          select_input.selectize.clear();
          select_input.selectize.clearOptions(); 

        } else {
        $("input[name='" + id_name + "']").val(data_filter[0]["id"]);
        console.log(data_filter[0]["id"]);
      }
      });
    },
    create: function (input, callback) {
      $.ajax({
        url: taskurl,
        data: { tasklist: { task: input.replace(";;", ";") } },
        method: "POST",
        error: function (res) {
          
          callback();
          $(this).val(input.replace(";;", ";"));
        },
        success: function (response) {
          console.log(response);
          callback({ value: response.id, task: input.replace(";;", ";") });
          $(this).val(input.replace(";;", ";"));
        },
      });
    },
  });

  $(".selectizecropsupply").selectize({
    maxItems: 1,
    delimiter: ';;',
    valueField: "supply",
    labelField: "supply",
    searchField: "supply",
    preload: true,
    load: function (query, callback) {
      console.log(query);
      $.ajax({
        url: supplyquery,
        data: { q: query },
        dataType: "json",
        type: "GET",
        error: function (res) {
          callback();
        },
        success: function (res) {
          callback(res);
        },
      });
    },
    render: {
      item: function (data, escape) {
        return (
          "<div class = 'select-id' data-id='" +
          data.id +
          "'>" +
          data.supply +
          "</div>"
        );
      },
      options: function (data, escape) {
        return "<div>" + data.supply + "</div>";
      },
    },
    onChange: function (value) {
      let id_name = $(this)[0].$input["0"].name.replace(
        "[supply_name]",
        "[supply_id]"
      );
      $.getJSON(supplyquery, function (data) {
        let data_filter = data.filter((element) => element.supply == [value]);
        $("input[name='" + id_name + "']").val(data_filter[0]["id"]);
        console.log(data_filter[0]["id"]);
      });
    },
    create: function (input, callback) {
      $.ajax({
        url: supplyurl,
        data: { supply: { supply: input.replace(";;", ";") } },
        method: "POST",
        error: function (res) {
          
          callback();
          $(this).val(input.replace(";;", ";"));
        },
        success: function (response) {
          console.log(response);
          callback({ value: response.id, supply: input.replace(";;", ";") });
          $(this).val(input.replace(";;", ";"));
        },
      });
    },
  });

  $(".selectizeplantinglocation").selectize({
    labelField: "name",
    searchField: "name",
    render: {
      item: function (item, escape) {
        return '<div class="">' + escape(item.name.split(":::")[0]) + "</div>";
      },
      option: function (item, escape) {
        return (
          '<div class = "d-flex flex-row pb-2">' +
          escape(item.name.split(":::")[0]) +
          '<span class=" ml-auto mr-2 text-muted" style="font-size: 12px;">' +
          escape(
            item.name.replace(item.name.split(":::")[0], "").replace(":::", "")
          ) +
          "</span>" +
          "</div>"
        );
      },
    },
  });
  

  $("form").on("cocoon:after-insert", function (event, addedItem) {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: "hover focus",
    });


    $(addedItem).find(".harvest_unit_selectize").selectize({
    create: function(input, callback) {
      selectizeCallback = callback;

      $(".create-unit-modal").modal();
      $("#new_harvestunit").trigger("reset");

      $("#harvestunit_custom_item").val(input);
      $(".alert").hide();

    }
  });
    
    $(addedItem).find('.selectize-small').selectize();

    $(addedItem)
      .find(".selectizelocation")
      .selectize({ plugins: ["remove_button"] });
    $(addedItem).find(".selectize_assign").selectize();

    $(addedItem).find(".selectizeplantinglocation").selectize({
      labelField: "name",
      searchField: "name",
      preload: true,
      render: {
        item: function (item, escape) {
          return '<div class="">' + escape(item.name.split(":::")[0]) + "</div>";
        },
        option: function (item, escape) {
          return (
            '<div class = "d-flex flex-row pb-2">' +
            escape(item.name.split(":::")[0]) +
            '<span class=" ml-auto mr-2 text-muted" style="font-size: 12px;">' +
            escape(
              item.name.replace(item.name.split(":::")[0], "").replace(":::", "")
            ) +
            "</span>" +
            "</div>"
          );
        },
      },
    });

    $(addedItem)
      .find(".selectizevariety")
      .selectize({
        maxItems: 1,
        delimiter: ';;',
        valueField: "variety",
        labelField: "variety",
        searchField: "variety",
        preload: true,
        load: function (query, callback) {
          console.log(query);
          $.ajax({
            url: varietyquery,
            data: { q: query },
            dataType: "json",
            type: "GET",
            error: function (res) {
              callback();
            },
            success: function (res) {
              callback(res);
            },
          });
        },
        render: {
          item: function (data, escape) {
            return (
              "<div class = 'select-id' data-id='" +
              data.id +
              "'>" +
              data.variety +
              "</div>"
            );
          },
          options: function (data, escape) {
            return "<div>" + data.variety + "</div>";
          },
        },
        onChange: function (value) {
          let id_name = $(this)[0].$input["0"].name.replace(
            "[variety]",
            "[varietyselector_id]"
          );

          $.getJSON(varietyquery, function (data) {
            let data_filter = data.filter(
              (element) => element.variety == [value]
            );
            $("input[name='" + id_name + "']").val(data_filter[0]["id"]);
            console.log(data_filter[0]["id"]);
          });
        },

        create: function (input, callback) {
          $.ajax({
            url: varietyurl,
            data: { varietyselector: { variety: input.replace(";;", ";") } },
            method: "POST",
            error: function (res) {
              
              callback();
              $(this).val(input.replace(";;", ";"));
                        },
            success: function (response) {
              console.log(response);
              callback({ value: response.id, variety: input.replace(";;", ";") });
              $(this).val(input.replace(";;", ";"));
            },
          });
        },
      });

  
     $(addedItem)
      .find(".selectizecroptask")
      .selectize({
        maxItems: 1,
        delimiter: ';;',
        valueField: "task",
        labelField: "task",
        searchField: "task",
        preload: true,
        load: function (query, callback) {
          console.log(query);
          $.ajax({
            url: taskquery,
            data: { q: query },
            dataType: "json",
            type: "GET",
            error: function (res) {
              callback();
            },
            success: function (res) {
              callback(res);
            },
          });
        },
        render: {
          item: function (data, escape) {
            return (
              "<div class = 'select-id' data-id='" +
              data.id +
              "'>" +
              data.task +
              "</div>"
            );
          },
          options: function (data, escape) {
            return "<div>" + data.task + "</div>";
          },
        },
        onChange: function (value) {
          let id_name = $(this)[0].$input["0"].name.replace(
            "[task]",
            "[tasklist_id]"
          );
          let select_input = $(this)[0].$input["0"]
      
          $.getJSON(taskquery, function (data) {
            let data_filter = data.filter((element) => element.task == [value]);
    
            if (data_filter[0] == null) {
              $("input[name='" + id_name + "']").val(null);
              select_input.selectize.clear();
              select_input.selectize.clearOptions(); 
    
            } else {
            $("input[name='" + id_name + "']").val(data_filter[0]["id"]);
            console.log(data_filter[0]["id"]);
          }
          });
        },
        create: function (input, callback) {
          $.ajax({
            url: taskurl,
            data: { tasklist: { task: input.replace(";;", ";") } },
            method: "POST",
            error: function (res) {
              
              callback();
              $(this).val(input.replace(";;", ";"));
            },
            success: function (response) {
              console.log(response);
              callback({ value: response.id, task: input.replace(";;", ";") });
              $(this).val(input.replace(";;", ";"));
              let id_name = $(this)[0].$input["0"].name.replace(
                "[task]",
                "[tasklist_id]"
              );
              let id = $(this)[0]
                .$input.closest(".form-group")
                .find(".select-id")
                .data("id");
              console.log(id_name);
              $("input[name='" + id_name + "']").val(id);
            },
          });
        },
      });
      $(addedItem)
      .find(".selectizecropsupply").selectize({
        maxItems: 1,
        delimiter: ';;',
        valueField: "supply",
        labelField: "supply",
        searchField: "supply",
        preload: true,
        load: function (query, callback) {
          console.log(query);
          $.ajax({
            url: supplyquery,
            data: { q: query },
            dataType: "json",
            type: "GET",
            error: function (res) {
              callback();
            },
            success: function (res) {
              callback(res);
            },
          });
        },
        render: {
          item: function (data, escape) {
            return (
              "<div class = 'select-id' data-id='" +
              data.id +
              "'>" +
              data.supply +
              "</div>"
            );
          },
          options: function (data, escape) {
            return "<div>" + data.supply + "</div>";
          },
        },
        onChange: function (value) {
          let id_name = $(this)[0].$input["0"].name.replace(
            "[supply_name]",
            "[supply_id]"
          );
          $.getJSON(supplyquery, function (data) {
            let data_filter = data.filter((element) => element.supply == [value]);
            $("input[name='" + id_name + "']").val(data_filter[0]["id"]);
            console.log(data_filter[0]["id"]);
          });
        },
        create: function (input, callback) {
          $.ajax({
            url: supplyurl,
            data: { supply: { supply: input.replace(";;", ";") } },
            method: "POST",
            error: function (res) {
              
              callback();
              $(this).val(input.replace(";;", ";"));
            },
            success: function (response) {
              console.log(response);
              callback({ value: response.id, supply: input.replace(";;", ";") });
              $(this).val(input.replace(";;", ";"));
            },
          });
        },
      });
  });
});
