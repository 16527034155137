window.addEventListener("trix-file-accept", function (event) {
  if ($(".blog").length > 0) {
    const maxFileSize = 1024 * 1024 * 100; // 100MB
    if (event.file.size > maxFileSize) {
      event.preventDefault();
      alert("Sorry!  Blog only supports attaching files up to 100MB in size.");
    }
      } else {
      const maxFileSize = 1024 * 1024 * 3; // 3MB
    if (event.file.size > maxFileSize) {
      event.preventDefault();
      alert("Sorry!  VeggieCropper only supports attaching files up to 3MB in size.");
    }
    }

 
});





//window.addEventListener("trix-file-accept", function (event) {
 // const acceptedTypes = ["image/jpeg", "image/png"];
 // if (!acceptedTypes.includes(event.file.type)) {
 //   event.preventDefault();
 //   alert("Sorry!  Cropper only supports attaching of jpeg or png files");
//  }
// });
